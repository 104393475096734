//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
    name:'pdf-contract-retailer',
    props:['record','wholesaller'],
    data(){
        return {
            firstParagraph: `أولاً: شركة رفتك للتكنولوجيا والصناعة Rftic سجل تجاري رقم 10294 والوكيل الحصري والوحيد لشركة بيوريكم تايوان وتعمل في جمهورية مصر العربية بأسم بيوريكم إيجيبت . ومقرها الكيلو 24 - طريق إسكندرية - القاهرة الصحراوي بجوار الجراري 2 - مرغم الإسكندرية - ويمثلها في هذا العقد الدكتور / السيد فيصل السيد بصفته الممثل القانوني للشركة`,
            init:`حيث أن الطرف الأول وكيل لشركة رفتك (بيوريكم إيجيبت الوكيل الوحيد والحصري لمنتجات شركة بيوريكم تايوان لتنقية ومعالجة المياه) بكافة اشكالها من أجهزه وقطع غيار والتي تحمل العلامة التجارية`,
            initPart2:`ولرغبة الطرف الثاني في أن يكون موزع قطاعي للطرف الأول فقد اتفقا على ما يلى :`,
            firstPoint:`يعتبر البند التمهيدي السابق جزء لا يتجزأ من هذا العقد ومكملاً ومفسراً له في كل بنوده .`,
            secondPoint:`يمنح الطرف الأول وهو وكيل لشركة رفتك (بيوريكم إيجيبت) صاحبة العلامة التجارية`,
            secondPointPart2:`في مصر الطرف الثاني بموجب هذا العقد الحق في توزيع وبيع منتجات تنقية ومعالجة المياه الخاصة بشركة بيوريكم تايوان في مصر`,
            secondPointPart3:`داخل نطاق منطقة توزيعه والمتفق عليها والموضحة بالوصف التالي :`,
            point3: `ولا يجوز تجديده الا بموافقة كتابية من الطرف الأول ويحق للطرف الأول انهاء العقد بارادته المنفردة ولو قبل انتهاء مدة هذا العقد
            ولا يجوز للطرف الثاني بيع حقه في توزيع وبيع منتجات`,
            point3Part2: `الوارد بهذا العقد أو التنازل عنه إلى الغير إلا بعد موافقة كتابيه من الطرف الأول .`,
        }
    },
    methods:{
        dot(num){
            return '.'.repeat(num)
        }
    },
    computed:{
        day(){
            if(this.record.contractDate){
                return moment(this.record.contractDate,'DD/MM/YYYY').locale('ar').format('dddd')
            }
            return moment().locale('ar').format('dddd')
        },
        date(){
            if(this.record.contractDate){
                return this.record.contractDate
            }
            return moment().locale('ar').format('DD/MM/YYYY')
        },
        area(){
            if(!this.record.area) return ``
            let txt = null;
            for(let index = 0; index < this.record.area.length; index++) {
                const element = this.record.area[index];
                
                txt? txt = `${txt} - ${element}` : txt = element

            }
            return txt
        },
        start(){
            return moment().locale('ar').format('YYYY/MM/DD')
        },
        end(){
            return moment().locale('ar').add(6,'months').format('YYYY/MM/DD')
        },
    }
}
