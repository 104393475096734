//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  name: 'pdf-contract',
  props: ['record'],
  data() {
    return {
      firstParagraph: `أولاً: شركة رفتك للتكنولوجيا والصناعة Rftic سجل تجاري رقم 10294 والوكيل الحصري والوحيد لشركة بيوريكم تايوان وتعمل في جمهورية مصر العربية بأسم بيوريكم إيجيبت . ومقرها الكيلو 24 - طريق إسكندرية - القاهرة الصحراوي بجوار الجراري 2 - مرغم الإسكندرية - ويمثلها في هذا العقد الدكتور / السيد فيصل السيد بصفته الممثل القانوني للشركة أو المفوض عنها`,
      init: `حيث أن الطرف الأول هو الوكيل الحصري والوحيد في مصر لمنتجات شركة بيوريكم تايوان لتنقية ومعالجة المياه بكافة أشكالها من
            أجهزة وقطع غيار والتي تحمل العلامة التجارية`,
      initPart2: `ولرغبة الطرف الثاني في أن يكون موزعاً معتمد للطرف الأول فقد اتفقا على ما يلى :`,
      firstPoint: `يعتبر البند التمهيدي السابق جزء لا يتجزأ من هذا العقد ومكملاً ومفسراً له في كل بنوده .`,
      secondPoint: `يمنح الطرف الأول وهو الوكيل الحصري لعلامة`,
      secondPointPart2: `في مصر الطرف الثاني بموجب هذا العقد الحق في توزيع وبيع منتجات تنقية ومعالجة المياه الخاصة بشركة بيوريكم تايوان وذلك بمحافظة`,
      secondPointPart3: `داخل نطاق منطقة توزيعه والمتفق عليها والموضحة بالوصف التالي :`,
    };
  },
  methods: {
    dot(num) {
      return '.'.repeat(num);
    },
  },
  computed: {
    day() {
      // debugger
      if(this.record.contractDate){
        return moment(this.record.contractDate, 'DD/MM/YYYY').locale('ar').format('dddd')
      }
      return moment().locale('ar').format('dddd');
    },
    date() {
      if(this.record.contractDate){
        return this.record.contractDate
      }
      return moment().locale('ar').format('DD/MM/YYYY');
    },
    area() {
      if (!this.record.area) return ``;
      let txt = null;
      for (
        let index = 0;
        index < this.record.area.length;
        index++
      ) {
        const element = this.record.area[index];

        txt
          ? (txt = `${txt} - ${element}`)
          : (txt = element);
      }
      return txt;
    },
  },
};
