//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
// import { i18n } from '@/vueI18n'

export default {
    name:'pdf-contract-page2',
    data(){
        return {
            logo: `<img src="https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fpdf%2Fbranch_logo.png?alt=media&token=367614e6-2b6c-4da0-8adb-a02af42fce2d" style="vertical-align: middle !important;" height="14px" width="70px"/>`,
            dots:'...............',
            point3: `ولا يجوز تجديده الا بموافقة كتابية من الطرف الأول ويحق للطرف الأول انهاء العقد بارادته المنفردة ولو قبل انتهاء مدة هذا العقد
            ولا يجوز للطرف الثاني بيع حقه في توزيع وبيع منتجات`,
            point3Part2: `الوارد بهذا العقد أو التنازل عنه إلى الغير إلا بعد موافقة كتابيه من الطرف الأول .`,
            // point4: `يضع الوكيل الحصرى للعلامة التجارية 
            // <img src="https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fpdf%2Fbranch_logo.png?alt=media&token=367614e6-2b6c-4da0-8adb-a02af42fce2d" style="vertical-align: middle !important;" height="14px" width="70px"/>
            //  شروط الإستبدال والاسترجاع للسلع المباعة وتعديلها مع ما يتماشى بشروط جهاز حماية المستهلك دون الإخلال ببنود هذا العقد.`,
            point4: `يضع الطرف الأول شروط الإستبدال والإسترجاع للسلع المباعة وتعديلها مع ما يتماشى بشروط جهاز حماية المستهلك دون الإخلال ببنود هذا العقد .`,
            point5:`يلتزم البائع النهائي ( تاجر جملة أو تجزئة ) بإلزام العميل النهائي بتحميل تطبيق بيوريكم إيجيبت من شبكة الانترنت من خلال برنامج (App Store) أو (Play Store)  وذلك لتفعيل الضمان من خلاله والا يعتبر المنتج خارج الضمان ولا يحق للعميل الرجوع علي الشركة لخروجه من الضمان كما يلتزم البائع النهائى بتقديم شهادة الضمان المرفقة مع المنتج للمستهلك النهائى بعد ختمها بختم شركته ويكون مسئول عن عيوب التركيب والتشغيل على أن يلتزم الطرف الأول بعيوب الصناعة خلال فترة الضمان.`,
            point6: `

يعمل الطرف الثانى بإسمه أو اسم شركته التجارية ولا يحق له أن يعمل أو يتعامل مع الغير بأسم الطرف الأول أو نيابة عنه أو بأسم شركة بيوريكم حيث أن هذا العقد خاص بتوزيع وبيع منتجات بيوريكم فقط فالطرف الأول غير مسئول أمام الغير عن تصرفات الطرف الثاني، كما لا يحق ايضاً للطرف الثاني تاجر الجملة استخدام العلامة التجارية الخاصة بالطرف الأول أو العلامة التجارية لشركة بيوريكم                  بعد انتهاء مدة هذا العقد. 
`,
            point7: `يحدد الطرف الأول أسعار بيع المنتجات موضوع هذا العقد عن طريق قوائم أسعار بمستوياتها المختلفة لبيع الجملة والتجزئة والمستهلك
            ويلتزم الطرف الثاني بهذه القوائم ويُلزم بها تجار التجزئة والموزعين التابعين ويلتزم الطرف الثاني بنفس
            الشروط في حالة البيع للعميل النهائي مباشرة ويحتفظ الطرف الأول لنفسه بالحق في تعديل هذه القوائم من وقت لأخر وإبلاغ الطرف
            الثاني بذلك التعديل وذلك حفاظا على سياسة البيع الخاصة بالطرف الأول .`,
            point8: `لا يحق للطرف الثاني أن يدخل أي تغييرات على المنتجات المباعة سواء بالتعديل أو الإستبدال كما يُلزم بذلك تجار التجزئة المتعامل معهم ويلتزم
            بإخطار الطرف الأول بتقرير شهري عن حجم مبيعاته الشهرية من أجهزة وقطع الغيار بيوريكم .`,
            point9:`يلتزم الطرف الثاني ببيع المنتجات`,
            point9Part2:`موضوع العقد لجهات لها سجل تجاري وبطاقة ضريبية في نشاط فلاتر المياه كما لا يجوز
            للطرف الثاني نهائياً بيع منتجات`,
            point9Part3:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها .`,
        }
    },
    computed:{
        start(){
            return moment().locale('ar').format('YYYY/MM/DD')
        },
        end(){
            return moment().locale('ar').add(6,'months').format('YYYY/MM/DD')
        },
        i18n(key, args) {
      return this.$t(key, args);
    },
    }
}
