//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {i18n} from '@/i18n'
import moment from 'moment'

export default {
    name:'pdf-contract-page2-retailer',
    data(){
        return {
            dots:'...............',
            point3: `ولا يجوز تجديده الا بموافقة كتابية من الطرف الأول ويحق للطرف الأول انهاء العقد بارادته المنفردة ولو قبل انتهاء مدة هذا العقد
            ولا يجوز للطرف الثاني بيع حقه في توزيع وبيع منتجات`,
            point3Part2: `الوارد بهذا العقد أو التنازل عنه إلى الغير إلا بعد موافقة كتابيه من الطرف الأول .`,
            point4: `يضع الوكيل الحصرى للعلامة التجارية 
            <img src="https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fpdf%2Fbranch_logo.png?alt=media&token=367614e6-2b6c-4da0-8adb-a02af42fce2d" height="14px" width="70px"/>
             شروط الإستبدال والاسترجاع للسلع المباعة وتعديلها مع ما يتماشى بشروط جهاز حماية المستهلك دون الإخلال ببنود هذا العقد.`,
            // point4: `يضع الطرف الأول شروط الإستبدال والإسترجاع للسلع المباعة وتعديلها مع ما يتماشى بشروط جهاز حماية المستهلك دون الإخلال ببنود هذا العقد .`,
            point5:`يلتزم تاجر التجزئة بالزام العميل النهائى بتحميل تطبيق بيوريكم إيجيبت من شبكة الانترنت من خلال برنامج (App Store) أو (Play Store)  وذلك لتفعيل الضمان من خلاله والا يعتبر المنتج خارج الضمان ولا يحق للعميل الرجوع علي الشركة لخروجه من الضمان كما يلتزم تاجر التجزئة بتقديم شهادة الضمان المرفقة مع المنتج للمستهلك النهائى بعد ختمها بختم شركته ويكون مسئول عن عيوب التركيب والتشغيل على أن يلتزم الطرف الأول بعيوب الصناعة خلال فترة الضمان.`,
            point6: `لا يحق للطرف الثاني أن يعمل بأسم الطرف الأول تاجر الجملة حيث أن الطرف الأول لم يمنح الطرف الثاني بموجب هذا العقد أي حق في التعامل مع الغير بإسمه أو نيابة عنه فالطرف الأول غير مسئول أمام الغير عن تصرفات الطرف الثاني، كما لا يحق للطرف الثاني الموزع استخدام العلامة التجارية الخاصة بالطرف الأول أو العلامة التجارية لشركة بيوريكم`,
            point6Part2:`بعد انتهاء هذا العقد.`,
            point7: `يلتزم الطرف الثاني باسعار بيع المنتجات موضوع هذا العقد عن طريق قوائم الأسعار المحددة من جانب شركة رفتك (بيوريكم إيجيبت) الوكيل الحصري والوحيد لبيوريكم تايوان .`,
            point8: `لايحق للطرف الثاني أن يدخل أي تغييرات علي المنتجات المباعة سواء بالتعديل أو الإستبدال ولا يجوز للطرف الثاني نهائياً بيع منتجات `,
            point8Part2:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها ويلتزم بإخطار الطرف الأول بتقرير شهري عن حجم مبيعاته الشهرية من أجهزة و قطع الغيار بيوريكم .`,
            // dots:'...............',            
            // point8:`لايحق للطرف الثاني أن يدخل أي تغييرات علي المنتجات المباعة سواء بالتعديل أو الإستبدال ولا يجوز للطرف الثاني نهائياً بيع منتجات`,
            // point8Part2:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها ويلتزم بإخطار الطرف الأول بتقرير شهري عن حجم مبيعاته الشهرية من أجهزة و قطع الغيار بيوريكم .`,
            // point9Part3:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها`,
            point9:`يلتزم الطرف الثاني بتوزيع وبيع منتجات`,
            point9Part2:`خلال مدة العقد ويمنح له الطرف الأول مدة شهرين من تاريخ أنتهاء هذا العقد كحد أقصى لتسويق المنتجات المتبقية لديه وبنفس الشروط المتفق عليها بهذا العقد ولا يحق للطرف الثاني تغيير الاسعار للتخلص من المنتجات
            (اتباع سياسة حرق الأسعار) ولا يحق التعامل نهائياً بأسم منتجات بيوريكم وماركته المشهرة`,
            point9Part3:`سواء بالبيع أو توزيع المنتجات بعد أنتهاء هذه المدة . `,
            // point10:`يعتبر عقد الوكالة لاغياً في حالة عدم تنفيذ الطرف الثاني لأي من بنود هذا العقد والطرف الأول الحق في الرجوع عليه ومسائلته مدنياً و جنائياً .`,
            point10:`يعتبر عقد الوكالة لاغياً في حالة عدم تنفيذ الطرف الثانى لأى من بنود هذا العقد وللطرف الأول الحق في الرجوع عليه ومسائلته مدنياً وجنائياً كما يحق للوكيل الحصرى التدخل للتأكد من تنفيذ بنود هذا العقد للحفاظ علي منتجاته واتخاذ كافة أجراءته كما لو كان طرف في هذا العقد .`,
            
        }
    },
    computed:{
        start(){
            return moment().locale('ar').format('YYYY/MM/DD')
        },
        end(){
            return moment().locale('ar').add(6,'months').format('YYYY/MM/DD')
        },
        i18n(key, args) {
      return this.$t(key, args);
    },
    }
}
