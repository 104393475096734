//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name:'pdf-contract-page3-retailer',
    props:['record','signature','userSignature','wholesaller','show'],
    data(){
        return {
            // dots:'...............',            
            // point8:`لايحق للطرف الثاني أن يدخل أي تغييرات علي المنتجات المباعة سواء بالتعديل أو الإستبدال ولا يجوز للطرف الثاني نهائياً بيع منتجات`,
            // point8Part2:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها ويلتزم بإخطار الطرف الأول بتقرير شهري عن حجم مبيعاته الشهرية من أجهزة و قطع الغيار بيوريكم .`,
            // // point9Part3:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها`,
            // point9:`يلتزم الطرف الثاني بتوزيع وبيع منتجات`,
            // point9Part2:`خلال مدة العقد ويمنح له الطرف الأول مدة شهرين من تاريخ أنتهاء هذا العقد كحد أقصى لتسويق المنتجات المتبقية لديه وبنفس الشروط المتفق عليها بهذا العقد ولا يحق للطرف الثاني تغيير الاسعار للتخلص من المنتجات
            // (اتباع سياسة حرق الأسعار) ولا يحق التعامل نهائياً بأسم منتجات بيوريكم وماركته المشهرة`,
            // point9Part3:`سواء بالبيع أو توزيع المنتجات بعد أنتهاء هذه المدة . `,
            point10:`يعتبر عقد الوكالة لاغياً في حالة عدم تنفيذ الطرف الثانى لأى من بنود هذا العقد وللطرف الأول الحق في الرجوع عليه ومسائلته مدنياً وجنائياً كما يحق للوكيل الحصرى التدخل للتأكد من تنفيذ بنود هذا العقد للحفاظ علي منتجاته واتخاذ كافة أجراءته كما لو كان طرف في هذا العقد .`,
            // point10:`يعتبر عقد الوكالة لاغياً في حالة عدم تنفيذ الطرف الثاني لأي من بنود هذا العقد والطرف الأول الحق في الرجوع عليه ومسائلته مدنياً و جنائياً .`,
            point11:`تسرى على هذا العقد الأحكام والقوانين المتعلقة بالوكالة التجارية والعلامة التجارية المنصوص عليها في القانون التجاري المصري وتختص
            محاكم الإسكندرية وحدها بنظر أي نزاع بخصوص هذا العقد .`,
            point12:`تحرر هذا ا لعقد من نسختين بيد كل طرف نسخة للعمل بموجبها .`
        }
    },
    methods:{
        dot(num){
            return '.'.repeat(num)
        }
    }
}
