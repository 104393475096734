//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name:'pdf-contract-page3',
    props:['record','signature','userSignature','show'],
    data(){
        return {
            dots:'...............',            
            // point9:`يلتزم الطرف الثاني ببيع المنتجات`,
            // point9Part2:`موضوع العقد لجهات لها سجل تجاري وبطاقة ضريبية في نشاط فلاتر المياه كما لا يجوز
            // للطرف الثاني نهائياً بيع منتجات`,
            // point9Part3:`لمحلات الأدوات الصحية والادوات المنزلية ومحلات السيراميك وما يماثلها .`,
            point10:`يلتزم الطرف الثاني بتوزيع وبيع منتجات`,
            point10Part2:`خلال مدة العقد ويمنح له الطرف الأول مدة شهرين من تاريخ أنتهاء هذا العقد كحد أقصى لتسويق المنتجات المتبقية لديه وبنفس الشروط المتفق عليها بهذا العقد ولا يحق للطرف الثاني تغيير الاسعار للتخلص من المنتجات
            (اتباع سياسة حرق الأسعار) ولا يحق التعامل نهائياً بأسم منتجات بيوريكم وماركته المشهرة`,
            point10Part3:`سواء بالبيع أو توزيع المنتجات بعد أنتهاء هذه المدة .`,
            point11:`يعتبر عقد الوكالة لاغياً في حالة عدم تنفيذ الطرف الثاني لأي من بنود هذا العقد و يحق للطرف الأول الرجوع عليه بالتعويض و مساءلته مدنياً وجنائياً`,
            point12:`تسرى على هذا العقد الأحكام والقوانين المتعلقة بالوكالة التجارية والعلامة التجارية المنصوص عليها في القانون التجاري المصري وتختص
            محاكم الإسكندرية وحدها بنظر أي نزاع بخصوص هذا العقد .`,
            point13:`تحرر هذا ا لعقد من نسختين بيد كل طرف نسخة للعمل بموجبها .`
        }
    },
    methods:{
        dot(num){
            return '.'.repeat(num)
        }
    }
}
